
import { useState, Fragment } from 'react'

import { useNavigate, Link, useLocation } from "react-router-dom"

import { Dialog, Popover, Transition } from '@headlessui/react'

import {
  Bars3Icon,
  XMarkIcon,
} from '@heroicons/react/24/outline'


import { AcademicCapIcon, ShieldCheckIcon, UserGroupIcon, BuildingOfficeIcon, BoltIcon, CurrencyDollarIcon, UserCircleIcon, UserPlusIcon, UsersIcon, EnvelopeOpenIcon, SquaresPlusIcon, ChatBubbleLeftRightIcon, FaceFrownIcon, LinkIcon, CircleStackIcon, ChatBubbleOvalLeftEllipsisIcon } from '@heroicons/react/20/solid'
import { LetterMIcon } from '../libs/svg'


import { ReactComponent as TextLogo } from "../media/logos/text_logo_320x132.svg"





const navigation = [
  // { name: 'Pricing', to: '/pricing' },
  { name: 'Company', to: '/about' },
  { name: 'Blog', to: 'https://blog.vansec.com' },
]

const products = [
  { name: 'Vansec Simulation', description: 'AI-powered spear phishing simulation', href: '/products/vansec-simulation', icon: BoltIcon },
  { name: 'Vansec Training', description: 'Next-gen cybersecurity coach', href: '/products/vansec-training', icon: AcademicCapIcon },
]

const solution_overview = [
  { name: 'Human Vulnerability Management', href: '/solutions/overview/human-vulnerability-management', icon: ShieldCheckIcon },
  { name: 'Social Engineering Database', href: '/solutions/overview/social-engineering-database', icon: CircleStackIcon },
  { name: 'MITRE ATT&CK', href: '/solutions/overview/mitre-attack', icon: LetterMIcon },
]

const solutions_use_case = [
  { name: 'Social Engineering', href: '/solutions/use-case/social-engineering', icon: FaceFrownIcon },
  { name: 'CEO Fraud', href: '/solutions/use-case/ceo-fraud', icon: UserCircleIcon }, // UserPlusIcon
  { name: 'Business Email Compromise', href: '/solutions/use-case/business-email-compromise', icon: EnvelopeOpenIcon },
  { name: 'Third Party Risk', href: '/solutions/use-case/third-party-risk', icon: LinkIcon },
  { name: 'Smishing, Vishing, Deepfakes', href: '/solutions/use-case/smishing-vishing-deepfakes', icon: ChatBubbleLeftRightIcon },
  { name: 'Multi-Channel Spear Phishing', href: '/solutions/use-case/multi-channel-spear-phishing', icon: SquaresPlusIcon },
]

const solutions_for = [
  { name: 'C-Suite', href: '/solutions/for/c-suite', icon: UserGroupIcon },
  { name: 'Finance', href: '/solutions/for/finance', icon: CurrencyDollarIcon },
  { name: 'Customer Support', href: '/solutions/for/customer-support', icon: ChatBubbleOvalLeftEllipsisIcon },
  { name: 'Human Resources', href: '/solutions/for/human-resources', icon: UsersIcon },
  { name: 'Entire Organization', href: '/solutions/for/organization', icon: BuildingOfficeIcon },
]


const Navbar = ({
  set_request_access_modal_is_open
}: { 
  set_request_access_modal_is_open : any
}) => {


  // Location
  const location = useLocation()

  // Navigate
  const navigate = useNavigate()




  // Whether menu is open or not
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)


  const [open_popover, set_open_popover] = useState("")
  
  return (
    <header className="absolute inset-x-0 top-0 z-20">

      {/* Top Navbar (Non-Burger) */}
      <nav className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8" aria-label="Global">
        <div className="flex lg:flex-1">
          <Link to="/" className="-m-1.5 p-1.5">
            <span className="sr-only">Vansec</span>
            <TextLogo className="h-4 w-auto" />
          </Link>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-white"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>

        {/* Main nav */}
        <div className="hidden lg:flex lg:gap-x-12">

          {/* Products */}
          <Popover 
            className="relative"
            onMouseEnter={() => set_open_popover("products")}
            onMouseLeave={() => set_open_popover("")}
          >
            <Popover.Button className="flex items-center gap-x-1 text-sm font-semibold leading-6 text-white outline-none">
              Products
              {/* <ChevronDownIcon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" /> */}
            </Popover.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
              show={open_popover === "products"}
            >
              <Popover.Panel className="absolute -left-16 top-full z-10 pt-6 w-screen max-w-md overflow-hidden bg-transparent">
                {/* <div className="p-4 bg-white rounded-md shadow-lg ring-1 ring-gray-900/5"> */}
                <div className="relative p-4 bg-white rounded-lg shadow-lg ring-1 ring-gray-900/5 before:absolute before:-top-2 before:-translate-y-2 before:left-24 before:transform before:-translate-x-1/2 before:border-8 before:border-transparent before:border-b-white">
                  <div className="flow-root">
                    {products.map((item) => (
                      <Link to={item.href} key={item.name} className="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-gray-50">
                        <div className="flex h-9 w-9 flex-none items-center justify-center rounded-lg">
                          <item.icon className="h-5 w-5 text-gray-600 group-hover:text-gray-900" aria-hidden="true" />
                        </div>
                        <div className="flex-auto">
                          <div className="block font-semibold text-gray-900">
                            {item.name}
                            <span className="absolute inset-0" />
                          </div>
                          <p className="mt-1 text-gray-600">{item.description}</p>
                        </div>
                      </Link>
                    ))}
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </Popover>

          {/* Solutions */}
          <Popover 
            className="relative"
            onMouseEnter={() => set_open_popover("solutions")}
            onMouseLeave={() => set_open_popover("")}
          >
            <Popover.Button className="flex items-center gap-x-1 text-sm font-semibold leading-6 text-white outline-none">
              Solutions
              {/* <ChevronDownIcon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" /> */}
            </Popover.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
              show={open_popover === "solutions"}
            >
              <Popover.Panel className="absolute -left-16 top-full z-10 pt-6 w-screen max-w-xl overflow-hidden bg-transparent">
                <div className="relative p-4 bg-white rounded-lg shadow-lg ring-1 ring-gray-900/5 before:absolute before:-top-2 before:-translate-y-2 before:left-24 before:transform before:-translate-x-1/2 before:border-8 before:border-transparent before:border-b-white text-sm leading-6">
                  <div className="grid grid-cols-3 gap-x-6 sm:gap-x-8 mx-4 my-2">
                    <div>
                      <h3 className="text-sm font-medium leading-6 text-gray-500">Overview</h3>
                      <div className="flow-root space-y-4 pt-4">
                        {solution_overview.map((item) => (
                          <Link to={item.href} key={item.name} className="group relative flex gap-x-2">
                            <div className="">
                              <item.icon className="h-4 w-4 text-gray-600 group-hover:text-gray-900" />
                            </div>
                            <div className="-translate-y-1 font-medium text-gray-600 hover:text-gray-900">
                              {item.name}
                            </div>
                          </Link>
                        ))}
                      </div>
                    </div>
                    <div>
                      <h3 className="text-sm font-medium leading-6 text-gray-500">Use Case</h3>
                      <div className="flow-root space-y-4 pt-4">
                        {solutions_use_case.map((item) => (
                          <Link to={item.href} key={item.name} className="group relative flex gap-x-2">
                            <div className="">
                              <item.icon className="h-4 w-4 text-gray-600 group-hover:text-gray-900" />
                            </div>
                            <div className="-translate-y-1 font-medium text-gray-600 hover:text-gray-900">
                              {item.name}
                            </div>
                          </Link>
                        ))}
                      </div>
                    </div>
                    <div>
                      <h3 className="text-sm font-medium leading-6 text-gray-500">For</h3>
                      <div className="flow-root space-y-4 pt-4">
                        {solutions_for.map((item) => (
                          <Link to={item.href} key={item.name} className="group relative flex gap-x-2">
                            <div className="">
                              <item.icon className="h-4 w-4 text-gray-600 group-hover:text-gray-900" />
                            </div>
                            <div className="-translate-y-1 font-medium text-gray-600 hover:text-gray-900">
                              {item.name}
                            </div>
                          </Link>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </Popover>

          {/* Rest of non-popover navigation */}
          {navigation.map((item) => (
            <Link key={item.name} to={item.to} className="text-sm font-semibold leading-6 text-white">
              {item.name}
            </Link>
          ))}
        </div>

        {/* Action nav */}
        <div className="hidden lg:flex lg:flex-1 lg:justify-end items-center space-x-6">

          <Link
            // to="https://calendly.com/vansec/vansec-discovery"
            // target="_blank"
            to="/get-started#top"
            // to="/?demo_is_active=true#demo"
            // onClick={() => set_request_access_modal_is_open(true)}
            className="rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-400 cursor-pointer"
          >
            Get started
          </Link>

          <Link to="https://app.vansec.com/login" target="_blank" className="text-sm font-semibold leading-6 text-white">
            Log in <span aria-hidden="true">&rarr;</span>
          </Link>

          

        </div>
      </nav>

      {/* Burger Nav */}
      <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
        <div className="fixed inset-0 z-50" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-gray-800 px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <Link to="/" className="-m-1.5 p-1.5" onClick={() => setMobileMenuOpen(false)}>
              <span className="sr-only">vansec</span>
              <TextLogo className="h-4 w-auto" />
            </Link>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-white"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">

              {/* Main nav */}
              <div className="space-y-2 py-6">

                {/* Products */}
                <Popover className="relative w-full">
                  <Popover.Button className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white hover:bg-gray-700 w-full justify-start flex">
                    Products
                    {/* <ChevronDownIcon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" /> */}
                  </Popover.Button>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                  >
                    <Popover.Panel className="absolute top-full -left-2 w-full z-10 mt-2 overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5">
                      <div className="p-4">
                        {products.map((item) => (
                          <Link
                            to={item.href}
                            onClick={() => setMobileMenuOpen(false)}
                            key={item.name}
                            className="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-gray-50"
                          >
                            <div className="flex h-9 w-9 flex-none justify-center rounded-lg">
                              <item.icon className="h-5 w-5 text-gray-600 group-hover:text-gray-900" aria-hidden="true" />
                            </div>
                            <div className="flex-auto">
                              <div className="block font-semibold text-gray-900">
                                {item.name}
                                <span className="absolute inset-0" />
                              </div>
                              <p className="mt-1 text-gray-600">{item.description}</p>
                            </div>
                          </Link>
                        ))}
                      </div>
                    </Popover.Panel>
                  </Transition>
                </Popover>

                {/* Solutions */}
                <Popover className="relative w-full">
                  <Popover.Button className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white hover:bg-gray-700 w-full justify-start flex">
                    Solutions
                    {/* <ChevronDownIcon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" /> */}
                  </Popover.Button>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                  >
                    <Popover.Panel className="absolute top-full -left-2 w-full z-10 mt-2 overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5 px-2">
                      <div className="p-4 text-sm space-y-6">
                        <div>
                          <h3 className="text-sm font-medium leading-6 text-gray-500">Overview</h3>
                          <div className="flow-root space-y-4 pt-4">
                            {solution_overview.map((item) => (
                              <Link to={item.href} onClick={() => setMobileMenuOpen(false)} key={item.name} className="group relative flex gap-x-2">
                                <div className="">
                                  <item.icon className="h-4 w-4 text-gray-600 group-hover:text-gray-900" />
                                </div>
                                <div className="-translate-y-0.5 font-medium text-gray-600 hover:text-gray-900">
                                  {item.name}
                                </div>
                              </Link>
                            ))}
                          </div>
                        </div>
                        <div>
                          <h3 className="text-sm font-medium leading-6 text-gray-500">Use Case</h3>
                          <div className="flow-root space-y-4 pt-4">
                            {solutions_use_case.map((item) => (
                              <Link to={item.href} onClick={() => setMobileMenuOpen(false)} key={item.name} className="group relative flex gap-x-2">
                                <div className="">
                                  <item.icon className="h-4 w-4 text-gray-600 group-hover:text-gray-900" />
                                </div>
                                <div className="-translate-y-0.5 font-medium text-gray-600 hover:text-gray-900">
                                  {item.name}
                                </div>
                              </Link>
                            ))}
                          </div>
                        </div>
                        <div>
                          <h3 className="text-sm font-medium leading-6 text-gray-500">Training</h3>
                          <div className="flow-root space-y-4 pt-4">
                            {solutions_for.map((item) => (
                              <Link to={item.href} onClick={() => setMobileMenuOpen(false)} key={item.name} className="group relative flex gap-x-2">
                                <div className="">
                                  <item.icon className="h-4 w-4 text-gray-600 group-hover:text-gray-900" />
                                </div>
                                <div className="-translate-y-0.5 font-medium text-gray-600 hover:text-gray-900">
                                  {item.name}
                                </div>
                              </Link>
                            ))}
                          </div>
                        </div>
                      </div>
                    </Popover.Panel>
                  </Transition>
                </Popover>

                {/* Rest of non-popover navigation */}        
                {navigation.map((item) => (
                  <Link
                    key={item.name}
                    to={item.to}
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white hover:bg-gray-700"
                    onClick={() => setMobileMenuOpen(false)}
                  >
                    {item.name}
                  </Link>
                ))}
              </div>

              {/* Action nav */}
              <div className="space-y-2 py-6">
                <Link
                  // to="https://calendly.com/vansec/vansec-discovery"
                  // target="_blank"
                  to="/get-started#top"
                  // to="/?demo_is_active=true#demo"
                  // onClick={() => set_request_access_modal_is_open(true)}
                  className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-white hover:bg-gray-700"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  Get started
                </Link>
                <Link
                  to="https://app.vansec.com/login"
                  target="_blank"
                  className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-white hover:bg-gray-700"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  Log in
                </Link>
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  )
}


export default Navbar;