import { useEffect, useState } from 'react'

import { useNavigate, Link, useSearchParams } from "react-router-dom"



import BackgroundIndex from '../components/BackgroundIndex'
import Demo from '../components/Demo'


import { mixpanel_client_track } from "../libs/mixpanelClient"

import { investors } from "../data/investors"



import landing_image from "../media/graphics/landing_image.png"
import simulation_image from "../media/graphics/simulation_image.png"
import training_image from "../media/graphics/training_image.png"

// Tailwind
import {
  ChatBubbleLeftRightIcon,
  IdentificationIcon,
  PlayCircleIcon,
  ChartPieIcon,
} from '@heroicons/react/20/solid'
import { Switch, RadioGroup } from '@headlessui/react'

const demo_is_active_options = [
  { value: 'off', label: 'Off', boolean: false },
  { value: 'on', label: 'On', boolean: true },
]

const simulation_features = [
  {
    name: 'Multi-message & interactive.',
    description:
      'Vansec’s patent pending multi-message simulation leverages AI to dynamically engage users over multiple emails and responses.',
    icon: ChatBubbleLeftRightIcon,
  },
  {
    name: 'Hyper-personalized.',
    description: 'Our AI connects to employee data such as manager & vendor information to deliver phishing simulations of varying difficulties, with emails and scenarios customized for each employee.',
    icon: IdentificationIcon,
  },
  {
    name: 'Realistic simulation.',
    description: 'Safely stress-test your organization’s security posture against today’s evolving cybersecurity threats.',
    icon: PlayCircleIcon,
  },
]

const training_features = [
  {
    name: 'Interactive.',
    description:
      'Alan leverages AI to engage your employees in short interactive conversations, topics ranging from various security concepts to post-simulation debrief.',
    icon: ChatBubbleLeftRightIcon,
  },
  {
    name: 'Hyper-relevant & personalized. ',
    description: 'Conversations with Alan are customized for each employee based on their data as well as what happened in the simulation they just went through.',
    icon: IdentificationIcon,
  },
  {
    name: 'Bite-sized.',
    description: 'Every conversation with Alan is bite-sized to ensure that the content is easily digestible and feels lightweight.',
    icon: ChartPieIcon,
  },
]


const stats = [
  { id: 1, name: 'Number of successful email attacks that are targeted spear-phishing attacks', value: '2 out of 3' },
  { id: 2, name: 'Percentage of known hacker groups that use spear-phishing as initial attack vector', value: '65%' },
  { id: 3, name: 'Average cost of data breach with phishing as initial attack vector', value: '$4.7M' },
  { id: 4, name: 'Percentage of security breaches that begin with phishing', value: '90%' },
]



function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}


const threat_texts = ['social engineering', 'third party risk', 'targeted phishing'];




const RotatingText = ({ texts, interval }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % texts.length);
    }, interval);

    return () => clearInterval(intervalId);
  }, [texts, interval]);

  return (
    <span className="relative inline-block align-top w-96">
      {texts.map((text, index) => (
        <span
          key={index}
          className={`absolute top-0 left-0 transition-all duration-1000 ease-in-out ${
            index === currentIndex ? 'translate-y-0 opacity-100' : 'opacity-0'
          }`}
          style={{ 
            transform: `translateY(${(() => {
              if (currentIndex === (index + texts.length - 1) % texts.length) {
                return "100%"
              }
              else if (currentIndex === index) {
                return "0%"
              }
              else if (currentIndex === (index + texts.length + 1) % texts.length) {
                return "-100%"
              }
            })()})`,
            opacity: (() => {
              if (currentIndex === (index + texts.length - 1) % texts.length) {
                return 0;
              } else if (currentIndex === index) {
                return 1;
              } else if (currentIndex === (index + texts.length + 1) % texts.length) {
                return 0;
              } else {
                return 0;
              }
            })()
          }}
        >
          {text}
        </span>
      ))}
      
    </span>
  );
};






const IndexPage = ({
    set_request_access_modal_is_open
  } : {
    set_request_access_modal_is_open : any
  }) => {


  // Navigate
  const navigate = useNavigate()

  // Search params
  const [search_params, _] = useSearchParams()

  const param_demo_is_active = search_params.get("demo_is_active")

  // States
  const [demo_video_modal_is_open, set_demo_video_modal_is_open] = useState(false);
  // const [demo_is_active, set_demo_is_active] = useState(demo_is_active_options[0]);
  const [demo_is_active, set_demo_is_active] = useState(param_demo_is_active === "true")

  useEffect(() => {

    // Mixpanel tracking
    mixpanel_client_track("www_index_visited", null)

    // Track whether visited via demo
    if (search_params.get("demo_is_active") === "true") {
      mixpanel_client_track("www_index_visited_via_demo", null)
    }
    
  }, [])

  useEffect(() => {

    const param_demo_is_active = search_params.get("demo_is_active") 

    if (!demo_is_active) {
      set_demo_is_active(param_demo_is_active === "true")
    }

  }, [search_params])

  return (
    <div id="top" className="bg-gray-900">
      
      {/* Hero section */}
      <div className="relative isolate overflow-hidden">
        {/* Background */}
        <BackgroundIndex />

        <div className="mx-auto max-w-7xl px-6 pb-0 pt-16 sm:pb-16 lg:flex lg:px-8 lg:pt-40">

          {/* Left side */}
          <div className="mx-auto max-w-2xl flex-shrink-0 lg:mx-0 lg:max-w-xl lg:pt-8">
            <h1 className="mt-10 text-4xl font-bold tracking-tight text-white sm:text-4xl">
              Stress-test your organization against{' '} <RotatingText texts={threat_texts} interval={3000} />
            </h1>
            <p className="mt-6 text-lg leading-8 text-gray-300">
              {/* Automated continuous human vulnerability management with targeted multi-message phishing simulation (patent pending) */}
              Automated social engineering pentest with targeted multi-message phishing simulation (patent pending)
            </p>
            <div className="mt-10 flex items-center gap-x-6">
              <Link
                // to="https://calendly.com/vansec/vansec-discovery"
                // target="_blank"
                to="/get-started#top"
                // onClick={() => set_request_access_modal_is_open(true)}
                className="rounded-md bg-indigo-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-400 cursor-pointer"
              >
                Get started
              </Link>
              <Link to="/?demo_is_active=true#demo" onClick={() => set_demo_is_active(true)} className="text-sm font-semibold leading-6 text-white">
                Interactive demo <span aria-hidden="true">→</span>
              </Link>
            </div>
          </div>

          {/* Right side */}
          <div className="flex flex-col w-full">

            {/* Anchor positioning */}
            <div id="demo" className="lg:absolute lg:top-0 h-0 w-0"></div>

            {/* Radio options */}
            <div className="relative mt-16 lg:absolute lg:top-12 lg:right-[20%] flex justify-center items-center space-x-4">
              <div className="text-white text-sm font-semibold">Interactive demo</div>

              <Switch
                checked={demo_is_active}
                onChange={set_demo_is_active}
                className={classNames(
                  demo_is_active ? 'bg-indigo-500' : 'bg-white/10',
                  'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none'
                )}
              >
                <span
                  className={classNames(
                    demo_is_active ? 'translate-x-5' : 'translate-x-0',
                    'pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                  )}
                >
                  <span
                    className={classNames(
                      demo_is_active ? 'opacity-0 duration-100 ease-out' : 'opacity-100 duration-200 ease-in',
                      'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity'
                    )}
                    aria-hidden="true"
                  >
                    <svg className="h-3 w-3 text-gray-400" fill="none" viewBox="0 0 12 12">
                      <path
                        d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </span>
                  <span
                    className={classNames(
                      demo_is_active ? 'opacity-100 duration-200 ease-in' : 'opacity-0 duration-100 ease-out',
                      'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity'
                    )}
                    aria-hidden="true"
                  >
                    <svg className="h-3 w-3 text-indigo-500" fill="currentColor" viewBox="0 0 12 12">
                      <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                    </svg>
                  </span>
                </span>
              </Switch>
            </div>

            {/* Main area */}
            <div className="mx-auto mt-16 flex max-w-2xl sm:mt-24 lg:ml-10 lg:mr-0 lg:mt-0 lg:max-w-none xl:ml-32 lg:h-auto h-auto">

              {demo_is_active
              // Demo
              ? <div className="max-w-3xl flex-none sm:max-w-5xl lg:max-w-none lg:visible h-auto lg:h-[540px] w-full">
                <Demo />
              </div>
              
              // Image
              : <div className="max-w-3xl flex-none sm:max-w-5xl lg:max-w-none lg:visible invisible h-0 lg:h-[540px]">
                <img
                  src={landing_image}
                  alt="App screenshot"
                  width={1442}
                  height={1044}
                  className="w-[46rem] rounded-md"
                  // className="w-[76rem] rounded-md bg-white/5 shadow-2xl ring-1 ring-white/10"
                />
              </div>}
            </div>

            
          </div>
          
        </div>
      </div>

      {/* Simulation section */}
      <div id="simulation" className="relative isolate overflow-hidden">
        <div className="mx-auto mt-4 max-w-7xl lg:mt-32 px-6 lg:px-8">
          <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2 ">
            
            <div className="mx-auto mt-16 flex max-w-2xl sm:mt-0 -ml-[50%] lg:-ml-[calc(100%+10rem)] lg:mt-0 lg:max-w-none lg:flex-none xl:-ml-[calc(100%+10rem)] lg:visible invisible h-0 lg:h-auto">
              <div className="max-w-3xl flex-none sm:max-w-5xl lg:max-w-none">
                <img
                  src={simulation_image}
                  alt="Simulation"
                  width={2432}
                  height={1442}
                  className="w-[76rem] rounded-md"
                  // className="w-[76rem] rounded-md bg-white/5 shadow-2xl ring-1 ring-white/10"
                />
              </div>
            </div>

            <div className="lg:pr-8 lg:pt-4">
              <div className="lg:max-w-lg">
                <h2 className="text-base font-semibold leading-7 text-indigo-400">Vansec Simulation</h2>
                <p className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">AI-powered advanced phishing simulation</p>
                <p className="mt-6 text-lg leading-8 text-gray-300">
                  Multi-message conversational spear phishing simulation for highly-targeted individuals, as well as basic templated phishing emails for the entire organization.
                </p>
                <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-300 lg:max-w-none">
                  {simulation_features.map((feature) => (
                    <div key={feature.name} className="relative pl-9">
                      <dt className="inline font-semibold text-white">
                        <feature.icon className="absolute left-1 top-1 h-5 w-5 text-indigo-500" aria-hidden="true" />
                        {feature.name}
                      </dt>{' '}
                      <dd className="inline">{feature.description}</dd>
                    </div>
                  ))}
                </dl>
              </div>
            </div>
            
          </div>
        </div>
      </div>
      

      {/* Training section */}
      <div id="training" className="relative isolate overflow-hidden">
        <div className="mx-auto mt-32 max-w-7xl px-6 sm:mt-56 lg:px-8">
          <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
            <div className="lg:pr-8 lg:pt-4">
              <div className="lg:max-w-lg">
                <h2 className="text-base font-semibold leading-7 text-indigo-400">Vansec Training</h2>
                <p className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">Next-gen cybersecurity coach</p>
                <p className="mt-6 text-lg leading-8 text-gray-300">
                  Meet Alan, your conversational AI cybersecurity coach loved by your employees.
                </p>
                <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-300 lg:max-w-none">
                  {training_features.map((feature) => (
                    <div key={feature.name} className="relative pl-9">
                      <dt className="inline font-semibold text-white">
                        <feature.icon className="absolute left-1 top-1 h-5 w-5 text-indigo-500" aria-hidden="true" />
                        {feature.name}
                      </dt>{' '}
                      <dd className="inline">{feature.description}</dd>
                    </div>
                  ))}
                </dl>
              </div>
            </div>
            <div className="mx-auto mt-0 flex max-w-2xl sm:mt-24 lg:ml-10 lg:mr-0 lg:mt-0 lg:max-w-none lg:flex-none xl:ml-12">
              <div className="max-w-3xl flex-none sm:max-w-5xl lg:max-w-none lg:visible invisible h-0 lg:h-auto">
                <img
                  src={training_image}
                  alt="Training"
                  width={2432}
                  height={1442}
                  className="w-[76rem] rounded-md"
                  // className="w-[76rem] rounded-md bg-white/5 shadow-2xl ring-1 ring-white/10"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      

      {/* Stats */}
      <div className="mx-auto mt-16 max-w-7xl px-6 lg:mt-36 lg:px-8">
        <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-xl">
          <h2 className="text-base font-semibold leading-8 text-indigo-400">Evolving threats</h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">
            The threat landscape is rapidly changing
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-300">
            Today’s cybersecurity threats are increasingly sophisticated & personalized.
          </p>
        </div>
        <dl className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-10 text-white sm:mt-20 sm:grid-cols-2 sm:gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-4">
          {stats.map((stat) => (
            <div key={stat.id} className="flex flex-col gap-y-3 border-l border-white/10 pl-6">
              <dt className="text-sm leading-6">{stat.name}</dt>
              <dd className="order-first text-3xl font-semibold tracking-tight">{stat.value}</dd>
            </div>
          ))}
        </dl>
      </div>

      {/* Feature section */}
      {/* <div className="mx-auto mt-32 max-w-7xl px-6 sm:mt-56 lg:px-8">
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-base font-semibold leading-7 text-indigo-400">Deploy faster</h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">
            Everything you need to deploy your app
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-300">
            Lorem ipsum dolor sit amet consect adipisicing elit. Possimus magnam voluptatum cupiditate veritatis in
            accusamus quisquam.
          </p>
        </div>
        <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
            {primaryFeatures.map((feature) => (
              <div key={feature.name} className="flex flex-col">
                <dt className="text-base font-semibold leading-7 text-white">
                  <div className="mb-6 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-500">
                    <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
                  </div>
                  {feature.name}
                </dt>
                <dd className="mt-1 flex flex-auto flex-col text-base leading-7 text-gray-300">
                  <p className="flex-auto">{feature.description}</p>
                  <p className="mt-6">
                    <Link to={feature.href} className="text-sm font-semibold leading-6 text-indigo-400">
                      Learn more <span aria-hidden="true">→</span>
                    </Link>
                  </p>
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div> */}

      {/* Feature section */}
      {/* <div className="mt-32 sm:mt-56">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl sm:text-center">
            <h2 className="text-base font-semibold leading-7 text-indigo-400">Everything you need</h2>
            <p className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">No server? No problem.</p>
            <p className="mt-6 text-lg leading-8 text-gray-300">
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque,
              iste dolor cupiditate blanditiis.
            </p>
          </div>
        </div>
        <div className="relative overflow-hidden pt-16">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <img
              src="https://tailwindui.com/img/component-images/dark-project-app-screenshot.png"
              alt="App screenshot"
              className="mb-[-12%] rounded-xl shadow-2xl ring-1 ring-white/10"
              width={2432}
              height={1442}
            />
            <div className="relative" aria-hidden="true">
              <div className="absolute -inset-x-20 bottom-0 bg-gradient-to-t from-gray-900 pt-[7%]" />
            </div>
          </div>
        </div>
        <div className="mx-auto mt-16 max-w-7xl px-6 sm:mt-20 md:mt-24 lg:px-8">
          <dl className="mx-auto grid max-w-2xl grid-cols-1 gap-x-6 gap-y-10 text-base leading-7 text-gray-300 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3 lg:gap-x-8 lg:gap-y-16">
            {secondaryFeatures.map((feature) => (
              <div key={feature.name} className="relative pl-9">
                <dt className="inline font-semibold text-white">
                  <feature.icon className="absolute left-1 top-1 h-5 w-5 text-indigo-500" aria-hidden="true" />
                  {feature.name}
                </dt>{' '}
                <dd className="inline">{feature.description}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div> */}

      {/* Investors */}
      <div className="mt-56">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <h2 className="text-center text-lg font-semibold leading-8 text-white">
            Backed by
          </h2>
          <div className="mx-auto mt-16 lg:mt-10 grid max-w-xl grid-cols-2 items-center gap-x-8 gap-y-10 sm:max-w-xl sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-3">
            <Link to={investors.kp.url} target="_blank" className="col-span-2 max-h-7 w-full object-contain lg:col-span-1 hover:scale-105 transition ease-in-out">
              <img
                className="max-h-7 w-full object-contain"
                src={investors.kp.image}
                
                alt="Transistor"
              />
            </Link>
            <Link to={investors.yc.url} target="_blank" className="col-span-2 max-h-14 w-full object-contain lg:col-span-1 hover:scale-105 transition ease-in-out">
              <img
                className="max-h-14 w-full object-contain"
                src={investors.yc.image}
                
                alt="Transistor"
              />
            </Link>
            <Link to={investors.gfc.url} target="_blank" className="col-span-2 max-h-6 w-full object-contain lg:col-span-1 hover:scale-105 transition ease-in-out">
              <img
                className="max-h-6 w-full object-contain"
                src={investors.gfc.image}
                
                alt="Transistor"
              />
            </Link>
          </div>
        </div>
      </div>

      {/* CTA section */}
      <div className="relative isolate mt-32 px-6 py-32 sm:mt-56 sm:py-40 lg:px-8">
        <svg
          className="absolute inset-0 -z-10 h-full w-full stroke-white/10 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="1d4240dd-898f-445f-932d-e2872fd12de3"
              width={200}
              height={200}
              x="50%"
              y={0}
              patternUnits="userSpaceOnUse"
            >
              <path d="M.5 200V.5H200" fill="none" />
            </pattern>
          </defs>
          <svg x="50%" y={0} className="overflow-visible fill-gray-800/20">
            <path
              d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z"
              strokeWidth={0}
            />
          </svg>
          <rect width="100%" height="100%" strokeWidth={0} fill="url(#1d4240dd-898f-445f-932d-e2872fd12de3)" />
        </svg>
        <div
          className="absolute inset-x-0 top-10 -z-10 flex transform-gpu justify-center overflow-hidden blur-3xl"
          aria-hidden="true"
        >
          <div
            className="aspect-[1108/632] w-[69.25rem] flex-none bg-gradient-to-r from-[#80caff] to-[#4f46e5] opacity-20"
            style={{
              clipPath:
                'polygon(73.6% 51.7%, 91.7% 11.8%, 100% 46.4%, 97.4% 82.2%, 92.5% 84.9%, 75.7% 64%, 55.3% 47.5%, 46.5% 49.4%, 45% 62.9%, 50.3% 87.2%, 21.3% 64.1%, 0.1% 100%, 5.4% 51.1%, 21.4% 63.9%, 58.9% 0.2%, 73.6% 51.7%)',
            }}
          />
        </div>
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
            Next-gen phishing simulation & security awareness training platform
          </h2>
          <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-300">
            Prepare your organization against evolving cybersecurity threats with realistic simulations & effective training, powered by AI. 
          </p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <Link
              // to="https://calendly.com/vansec/vansec-discovery"
              // target="_blank"
              to="/get-started#top"
              // onClick={() => set_request_access_modal_is_open(true)}
              className="rounded-md bg-indigo-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white cursor-pointer"
            >
              Get started
            </Link>
            <Link to="/?demo_is_active=true#demo" onClick={() => set_demo_is_active(true)} className="text-sm font-semibold leading-6 text-white">
              Interactive demo <span aria-hidden="true">→</span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}



export default IndexPage